import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Conditions from "../../components/Conditions";
import ServicesHeader from "../../components/ServicesHeader";
import AsthmaInfo from "../../components/services/AsthmaInfo";

const Asthma = () => {
  return (
    <Layout>
      <PageHeader text="Asthma" />
      <ServicesHeader>
        The feeling of being unable to catch your breath is scary, and with
        asthma, it’s a fear you have to face all the time. If you’re
        experiencing asthma symptoms, the board-certified physicians at Sunstate
        Medical Associates in Lake Mary, Florida, can help. They provide
        effective therapies to help reduce the frequency and severity of your
        asthma attacks. Find out how you can benefit from the expertise of the
        Sunstate Medical Associates team by calling their office today or book
        an appointment using the online form.
      </ServicesHeader>
      <AsthmaInfo />
      <Conditions />
    </Layout>
  );
};

export default Asthma;
