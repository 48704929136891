import React from "react";
import RequestButton from "../RequestButton";

const AsthmaInfo = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-3xl font-thin text-gray-800 mb-6">Asthma Q & A</p>

        <p className="text-xl text-gray-800 mb-2">What is asthma?</p>
        <p className="text-md text-gray-600 mb-2">
          Asthma is a condition that affects your breathing. It typically
          develops in childhood and can sometimes cause life-threatening
          situations. Asthma is a form of chronic obstructive pulmonary disease
          (COPD), like emphysema and chronic bronchitis.
        </p>
        <p className="text-md text-gray-600 mb-2">
          The cause of asthma is irritation and inflammation in your lungs that
          makes your air passages narrower. The result is less room for air to
          pass into your lungs, making it so you aren’t getting enough oxygen
          when you breathe.
        </p>
        <p className="text-md text-gray-600 mb-2">
          The irritation and inflammation are set off by an overreaction in your
          airways. This is called hyperreactivity, meaning your airways are
          extremely sensitive. Hyperreactivity causes your airways to spasm, so
          you experience:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Wheezing</li>
          <li>Chest constriction</li>
          <li>Shortness of breath</li>
          <li>Coughing</li>
        </ul>
        <p className="text-md text-gray-600 mb-2">
          If you’re having difficulties breathing it can make you feel panicky,
          which unfortunately makes the problem worse.
        </p>
        <p className="text-md text-gray-600 mb-12">
          People living with asthma usually have one or more triggers that bring
          on an attack.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What triggers an asthma attack?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Common triggers for asthma attacks include:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Pets and pet dander</li>
          <li>Mold spores</li>
          <li>Dust and dust mites</li>
          <li>Smoke</li>
          <li>Pollution</li>
          <li>Strong smells</li>
        </ul>
        <p className="text-md text-gray-600 mb-2">
          It’s not just what you breathe into your lungs that can cause airway
          constriction. It’s common for asthma attacks to strike if you’re
          stressed or feeling anxious or fearful.
        </p>
        <p className="text-md text-gray-600 mb-12">
          The Sunstate Medical Associates team can diagnose asthma by assessing
          your breathing using spirometry tests. These tests measure how well
          you breathe in and out and how fast you breathe.
        </p>

        <p className="text-xl text-gray-800 mb-2">How is asthma treated?</p>
        <p className="text-md text-gray-600 mb-2">
          If you have asthma, your provider at Sunstate Medical Associates will
          prescribe inhalers. You should carry your inhaler at all times and
          have spares in case of an emergency.
        </p>
        <p className="text-md text-gray-600 mb-2">
          If you feel your airways starting to tighten, you put the inhaler’s
          mouthpiece in your mouth, trigger the inhaler, and breathe in the mist
          that comes out. The mist helps moisten your airways and includes
          medications to reduce inflammation.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Using your inhaler helps ease the constriction in your airways and
          enables you to breathe more easily. As well as having your inhaler to
          help you manage asthma attacks, you should also try to avoid your
          triggers wherever possible.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Staying away from the substances that cause airway inflammation can
          help minimize hyperreactivity.
        </p>
        <p className="text-md text-gray-600 mb-10">
          If you’re struggling with your asthma, call Sunstate Medical
          Associates today or book an appointment online.
        </p>

        <RequestButton />
      </div>
    </div>
  );
};

export default AsthmaInfo;
